.PriceContainer{
    width:100vw;
    padding: 25px 0 25px 0;
    background-color: #ededed;

}

.Price{
    margin-right:23px;
}

h3{
    margin-bottom: 0px;
    color: #e44da0;
}

h5{
    font-size: clamp(11pt, 2vw, 12pt); 
    /* font-family: 'Mallanna'; */
    margin-bottom: -23px;
}

.PriceTitleSedan{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #31a2c9; 
    height:80px;

}
.BodyPrice{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}          

.StartPrice{
    margin-top:-50px;

}

/* .VehiclePrice{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width:330px;    
} */

.Price{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 330px;
}
.PriceWrapper{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;    
}   

.TextPrice{
    border: 2px solid black;    
    border-radius: 3px;
    height:450px;
    width: 405px;
}
.VehiclePrice{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width:404px;    
}

.BodyPrice{
   display:flex;
   justify-content: center;
   flex-direction: column;

}

.description{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:15px;
    font-family: 'Mallanna';

}

p2{
    width:250px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.TitleHeader{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: clamp(2rem, 2vw, 5rem); 
    font-family: 'Anton', sans-serif;
    margin-top:-15px;
    margin-bottom:10px;
}

@media screen and (max-width: 1100px){
.VehiclePrice{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width:330px;    
    flex-wrap: wrap;

}
}
@media screen and (max-width: 1100px){
    .PriceWrapper{
        flex-direction:column;
        align-items: center;
    }

    .TextPrice{
        border: 2px solid black;    
        border-radius: 3px;
        height:fit-content;
        width: 325px;
        margin-top: 6px;
    }
   
}