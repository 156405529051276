.ServiceContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw;
    background-color:#ededed;
}

.ServiceWrapper{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.Service{
    display: flex;
    align-items: center;
    
}
.BodyService{
    width:80%;    
}

.TextService{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    width: fit-content;
    min-height:225px;
    width: 370px;
}

h2{
    font-size: clamp(2rem, 1.5vw, 2rem); 
    font-family: 'Anton', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin:15px;
}
