.AboutContainer{
    min-height:300px;
    background-color: black;
}

.AboutWrapper{
    background-image: url('../images/carwash.jpg');
    min-height: 300px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;  
}

.AboutDetail{
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    font-size: clamp(1rem, 2vw, 2rem); 
    font-family: haboro-contrast-extended,sans-serif;
    font-weight: 600;
    font-style: normal;
    min-height:300px;
    color:white;
    margin: 0;
}

h8{
    font-size: clamp(1.5rem, 2vw, 3rem); 
    font-family: 'Anton', sans-serif;
    font-weight: 600;
    font-style: normal;}