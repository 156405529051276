.Container{
    background-image: url('./images/header.jpg');
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;  
    min-height: 600px; 
    width: 100vw;
    height: 100%;
    object-position: 50% 20%;
    object-fit: cover;
}

.Wrapper{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center; 
    min-height: 600px; 
    width: 100%;
    height: 100%;
}

.HeaderSection{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:20px;
    margin-top:40px;
}

.RightSide{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 200px; 
    color:white;
    margin: 0;
    /* font-family: 'Anton', sans-serif; */
    
}

h1{
    font-size: clamp(3rem, 4vw, 5rem); 
    margin: 0;
    font-family: 'Anton', sans-serif;
    
}

h7{
    font-size: clamp(1.5rem, 2vw, 4rem); 
    font-family: 'Mallanna';
    margin-top: -1rem;
}
.AboutDetail{
    width:65%;
    text-align: center;   
}



p1{
    font-size: clamp(1rem, 2vw, 15pt); 
    font-family: como, sans-serif;
    font-weight: 400;
    font-style: normal;
    

}

@media only screen and (max-width: 768px) {
    .HeaderSection {
        grid-template-columns: auto;
        grid-gap:0px;
        margin-top:0px;
    }

    .LeftSide{
        
    }
  }

  .BotInfo{
     display:flex;
     justify-content: center;
     

    }
    .LeftSide{
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color:white;
        
    }