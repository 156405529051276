
.FooterContainer{
    width: 100vw;
     display: flex; 
     justify-content: space-around; 
     background-color: black;
     padding-bottom: 40px;
}

.FooterWrapper{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 200px;
}

h6{
    font-size: clamp(2rem, 2vw, 25px); 
    font-family: 'Anton', sans-serif;
    margin-bottom: 5px;
    margin-top:45px;
    color: white;
}

.FooterBody{
    display:flex;
    flex-direction: column;
    width:20vw;
    
}
.Operation{
    display: flex;
    flex-direction: column;
}

p3{
    color:white;
}

@media screen and (max-width: 1100px){
    .FooterWrapper{
        display:grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
    .FooterBody{
        height: fit-content;
        width:80vw;
    }
}
