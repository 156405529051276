
.contact-form{
}
.Section{
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  padding-bottom: 3rem;
  background-color: #ededed;
}

.contact-box{
  background-color: #ededed; 
  margin: 20px 20px 20px 20px;
}



.input-row {
  display:flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.input-row, .input-group{
  flex-basis:45%;

}

@media only screen and (max-width: 768px) {
  .input-row, .input-group {
    display:flex;
    flex-direction: column; 
    width: 300px;
  }

  .Section{
    width:90%;

  }

  .contact-box{
    background-color: #ededed;    
    width: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    
  }
}

input, select{
  width:100%;
  border:none;
  border-bottom: 1px solid #ccc;
  outline:none;
  padding-bottom:5px;
  border:1px solid #333;
  border-right:none;
  border-top:none;
  border-left:none;
  background-color: #ededed;    
}
label{
  color:black;
}

.wrapper{
  padding-top:10px;
  cursor: pointer;
  display:flex;
  justify-content: center;
  align-items: center;
}
.fade {
  border:white;
  background-color: #31a2c9  ;
  transition: background-color 1s;
  border-radius: 4px;
  height:40px;
  width:380px;
}

.fade:hover {
  background-color: #59c4d8;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}

.fade:focus,
.fade:active {
  background-color: #59c4d8;
  transition: none;
}

.repoInfo{
  color:black;
  display:flex;
  justify-content: space-between;
  margin-top:20px;
}

button{
  font-family: 'Mallanna';
  font-size: clamp(18px, 2vw, 20px); 
  color: black

}
